import {BasicRadioGroup} from '@fl/cmsch-fe-library';
import React, {useCallback, memo, FC, ReactElement} from 'react';
import {opt, Opt} from 'ts-opt';

import {Ant} from 'common/ant';

import styles from './styles.sass';

export interface LanguageOption {
    language: string;
    label: string;
    icon: ReactElement;
}

interface Props {
    isSideMenu: boolean;
    languageOptions: Array<LanguageOption>;
    selectedLanguage: LanguageOption;
    onLanguageChange(language: string): void;
}

const LanguageSelectBase: FC<Props> = props => {
    const {isSideMenu, languageOptions, selectedLanguage, onLanguageChange} = props;

    const handleLanguageChange = useCallback((language: Opt<string>) =>
        onLanguageChange(language.orCrash('language must have value')), [onLanguageChange]);

    return (
        <Ant.Popover
            trigger={isSideMenu ? 'click' : 'hover'}
            placement={isSideMenu ? 'rightBottom' : 'bottomRight'}
            align={{offset: isSideMenu ? [-16, -11] : [-16, -16]}} /* eslint-disable-line no-magic-numbers */
            content={(
                <BasicRadioGroup
                    value={opt(selectedLanguage.language)}
                    onFieldChange={handleLanguageChange}
                    options={languageOptions.map(({language, label, icon}) => ({value: language, label, icon}))}
                />
            )}
        >
            <div className={styles.languageSelect}>
                {selectedLanguage.icon}
            </div>
        </Ant.Popover>
    );
};

export const LanguageSelect = memo(LanguageSelectBase);
