export const commonTranslations = {
    CS: {
        'common': {
            earTag: 'Ušní známka',
            earTagAbbr: 'Ušní z.',
            fathersLinReg: 'LIN-REG',
            fathersLinRegTooltip: 'Linie a registr býka',
            fathersName: 'Jméno otce',
            company: 'Podnik',
            countryCode: 'Kód země',
            stable: 'Stáj',
            breeding: 'Chov',
            breedingNumber: 'Číslo chovu',
            breedingNumberAbbr: 'Č. chovu',
            actions: 'Akce',
            codex: 'Kodex',
            breed: 'Plemeno',
            calveDate: 'Datum otelení',
            calveDateAbbr: 'Dat. otel.',
            close: 'Zavřít',
            proficiencyTestAbbr: 'KU',
            proficiencyTest: 'Kontrola užitkovosti',
            marked: 'Příznak',
            numOfDaughtersToMeasure: 'Dcer k hodnocení',
            numOfDaughtersToMeasureAbbr: 'Dcer k H.',
            numOfMeasuredDaughters: 'Hodnocených dcer',
            numOfMeasuredDaughtersAbbr: 'Hod. dcer',
            daysSinceCalveDate: 'Počet dní od otelení',
            daysSinceCalveDateAbbr: 'Otel. dní',
            joinedCalveDays: 'Věk při otelení',
            joinedCalveDaysAbbr: 'Věk ot.',
            inflectWord: {
                animal: {
                    first: 'zvíře',
                    second: 'zvířata',
                    third: 'zvířat',
                },
            },
            changesSaved: 'Změny byly uloženy',
            cancel: 'Zrušit',
            save: 'Uložit',
            edit: 'Editovat',
            create: 'Vytvořit',
            send: 'Odeslat',
            common: 'common',
            name: 'Jméno',
            registry: 'Registr',
            user: 'Uživatel',
            firstName: 'Jméno',
            lastName: 'Příjmení',
            approved: 'Schváleno',
            ratingDate: 'Dat. hodnocení',
            date: 'Datum',
            email: 'E-mail',
            whom: 'Komu',
            copy: 'Kopie',
            note: 'Poznámka',
            chooseAtLeastOne: 'Vyberte alespoň jednu položku',
            userCode: 'Bonitér',
            yes: 'Ano',
            no: 'Ne',
            selectItem: 'Vybrat',
            deselectItem: 'Zrušit výběr',
            select: 'Výběr',
            deselectAll: 'Zrušit výběr',
            actionPending: 'Akce probíhá',
            confirmAction: 'Opravdu chcete provést tuto akci?',
            confirm: 'Potvrdit',
            search: 'Hledat',
            detail: 'Detail',
            lactation: 'Laktace',
            ankleJoint: 'Charakter hlezenního kloubu',
            ankleJointAbbr: 'Char. hlez. kloubu',
            backAngle: 'Sklon zádě',
            backHeight: 'Výška v kříži',
            backLength: 'Délka zádě',
            backWidth: 'Šířka zádě',
            bodyDepth: 'Hloubka těla',
            bodyLength: 'Středová délka těla',
            bodyLengthAbbr: 'Stř. délka těla',
            calculatedExtremities: 'Končetiny (z výpočtu)',
            calculatedFrame: 'Rámec (z výpočtu)',
            calculatedMusculature: 'Osvalení (z výpočtu)',
            calculatedTotal: 'Celkem (z výpočtu)',
            calculatedUdder: 'Vemeno (z výpočtu)',
            adjustedExtremities: 'Končetiny',
            adjustedFrame: 'Rámec upravené',
            adjustedMusculature: 'Osvalení',
            adjustedTotal: 'Celkem',
            adjustedUdder: 'Vemeno',
            chestCircumference: 'Obvod hrudníku',
            colorType: 'Druh barvy',
            edematicUdder: 'Edematické vemeno',
            edematicUdderAbbr: 'Edem. vemeno',
            fitness: 'Kondice',
            frontExtremitiesOpenPosture: 'Otevřený postoj hrudních končetin',
            frontExtremitiesOpenPostureAbbr: 'Otev. hrud. kon.',
            frontTeatsPlacement: 'Rozmístění předních struků',
            frontTeatsPlacementAbbr: 'Roz. před. struků',
            frontTeatsSideways: 'Přední struky příliš ven',
            frontTeatsSidewaysAbbr: 'Př. str. příliš ven',
            frontUdderAttachmentAngle: 'Úhel předního upnutí',
            frontUdderAttachmentAngleAbbr: 'Úhel před. upnutí',
            frontUdderLength: 'Délka vemene předních čtvrtí',
            frontUdderLengthAbbr: 'Dél. vem. př. čtvrt.',
            frontFeetOrientation: 'Postoj předních končetin',
            funnelTeats: 'Nálevkovité struky',
            gradualUdder: 'Stupňovité vemeno',
            gradualUdderAbbr: 'Stup. vemeno',
            headColor: 'Barva hlavy',
            hoovesBackHeight: 'Paznehtová patka',
            hoovesBackHeightAbbr: 'Pazneht. patka',
            hoovesTorsion: 'Torze paznehtu',
            looseLoins: 'Volná bedra',
            looseShoulderBlade: 'Volná lopatka',
            mottleType: 'Strakatost',
            musculature: 'Osvalení',
            narrowBack: 'Úzká záď',
            narrowChest: 'Úzký hrudník',
            nervousness: 'Nervozita',
            openedHooves: 'Otevřené paznehty',
            openedHoovesAbbr: 'Otev. paznehty',
            pastern: 'Spěnka',
            posture: 'Kravský postoj',
            rearPostureHolstein: 'Postoj zezadu',
            rearPostureFleckvieh: 'Post. zad. konč.',
            rearTeatsPlacement: 'Rozmístění zadních struků',
            rearTeatsPlacementAbbr: 'Roz. zad. struků',
            rearTeatsPosition: 'Postavení zadních struků',
            rearTeatsPositionAbbr: 'Post. zad. struků',
            rearUdderAttachment: 'Nasazení vemene',
            rearUdderLength: 'Délka vemene zadních čtvrtí',
            rearUdderLengthAbbr: 'Dél. vem. zad. čtvrt.',
            roofedBack: 'Střechovitá záď',
            suspensoryLigament: 'Závěsný vaz',
            teatsCloseToEachOther: 'Struky z boku u sebe',
            teatsCloseToEachOtherAbbr: 'Str. z boku u sebe',
            shortRearTeats: 'Krátké zadní struky',
            shortRearTeatsAbbr: 'Kzs',
            teatsForward: 'Struky směrem dopředu',
            teatsForwardAbbr: 'Str. směr. dopředu',
            teatsLength: 'Délka struků',
            teatsWidth: 'Šířka struků',
            udderDepth: 'Hloubka vemene',
            udderPurity: 'Čistota vemene',
            weakBack: 'Měkký hřbet',
            withersHeight: 'Výška v kohoutku',
            withersHeightAbbr: 'Výška v koh.',
            bonesQuality: 'Kvalita kostí',
            calculatedBodyStructure: 'Stavba těla',
            calculatedLactationPerformance: 'Mléčná síla',
            calculatedOverallCondition: 'Celkem',
            adjustedBodyStructure: 'Stavba těla upravené',
            adjustedLactationPerformance: 'Mléčná síla upravené',
            adjustedOverallCondition: 'Kondice upravené',
            chestWidth: 'Šířka hrudníku',
            ribStructure: 'Struktura žeber',
            extremitiesDefect: 'Končetiny',
            foreUdderAttachment: 'Přední upnutí vemene',
            foreUdderAttachmentAbbr: 'Př. up. vemene',
            frame: 'Rámec',
            frameH: 'Tělesný rámec',
            height: 'Výška v kříži',
            hooves: 'Paznehty',
            pasternDefect: 'Spěnka',
            pasternDefectH: 'Vada spěnky',
            rearUdderHeight: 'Zadní upnutí vemene',
            rearUdderHeightAbbr: 'Zad. up. vemene',
            rearUdderHeightH: 'Výška zadního upnutí vemene',
            rearUdderHeightAbbrH: 'Výška zad. up. vemene',
            shortSacrum: 'Zkrácení kosti křížové',
            shouldersDefect: 'Vada plec',
            sidePosture: 'Postoj z boku',
            supernumeraryTeats: 'Závažné pastruky',
            teatsDefect: 'Struky',
            teatsDefectH: 'Vada struků',
            udderDefect: 'Vemeno',
            udderDefectH: 'Vada vemene',
            udderWidth: 'Šířka vemene',
            udderWidthH: 'Šířka zad. up. vemene',
            udderBalance: 'Balance vemene',
            upperLineDefect: 'Vada horní linie',
            walkQuality: 'Chodivost',
            alive: 'Živé',
            dead: 'Mrtvé',
            lastMilkingAt: 'Čas od dojení',
            tableGroupingHeaders: {
                general: 'Obecné informace',
                summary: 'Shrnutí',
                extremities: 'Končetiny',
                musculature: 'Osvalení',
                frame: 'Rámec',
                udder: 'Vemeno',
                frameRating: 'Rámec',
                musculatureRating: 'Osvalení',
                extremitiesRating: 'Končetiny',
                udderRating: 'Vemeno',
                frameDefects: 'Vady rámce',
                extremitiesDefects: 'Vady končetin',
                udderDefects: 'Vady vemene',
                colors: 'Barvy',
                other: 'Ostatní',
                lactationPerformance: 'Mléčná síla',
                bodyStructure: 'Stavba těla',
                bodyStructureDefects: 'Vady stavby těla',
                oldRating: 'Stará hodnocení',
                exterierRating: 'Hodnocení exteriéru',
            },
            form: {
                pristine: 'Data jsou beze změny.',
                submitting: 'Formulář se odesílá.',
                asyncValidating: 'Formulář se validuje.',
                notValid: 'Data ve formuláři nejsou validní.',
            },
            formStepNotValid: 'Data na aktuální stránce nejsou validní. Opravte je a pokračujte.',
            loading: 'Načítání…',
            userLoading: 'Načítání uživatele…',
            dataLoading: 'Načítání dat…',
            animalMarked: 'T',
            breedingInArealRating: 'P',
            district: 'Okres',
        },
        'mainMenu': {
            importKkut: 'Správa KKUT',
            breedings: 'Plánování',
            plan: 'Plán',
            measurements: 'Kontrola dat',
            adminPlans: 'Správa PDA',
            bulls: 'Býci',
            bullsOverview: 'Přehled býků',
            bullsInvoicingOverview: 'Přehled k fakturaci',
            breederSuites: 'Sestavy chovatelům',
            districts: 'Okresy',
            breeders: 'Podniky',
            databaseOfMeasured: 'Databáze hodnocených',
            administration: 'Administrace',
            logOut: 'Odhlásit se',
            repairs: 'Opravy',
            appInfo: 'O aplikaci',
            sentEmails: 'Odeslaná pošta',
        },
        'common/error': {
            unexpectedError: 'Neočekávaná chyba',
            unexpectedErrorOccurred: 'V aplikaci došlo k neočekávané chybě, ' +
            'problém byl zaznamenán do našeho systému a budeme se ho snažit co nejdříve vyřešit. ' +
            'Omlouváme se za způsobené potíže.',
            internalSagaError: 'Interní chyba Sagy',
            errorOccurredIn: 'Nastala chyba v Sage "{{sagaName}}"',
        },
        'common/validator': {
            isRequired: '{{label}} je povinný údaj.',
            mustBeChecked: 'Políčko {{label}} musí být zaškrtnuto.',
            oneChar: 'znak',
            lessThanFiveChars: 'znaky',
            moreChars: 'znaků',
            minLength: '{{label}} musí mít délku alespoň {{minLen}} {{characters}}.',
            maxLength: '{{label}} musí mít délku nejvýše {{maxLen}} {{characters}}.',
            wrongFormat: '{{label}} má nesprávný formát.',
            isNotInteger: '{{label}} není celé číslo.',
            isNotFloatNumber: '{{label}} není desetinné číslo.',
            minNumber: '{{label}} musí být minimálně číslo {{min}}.',
            maxNumber: '{{label}} musí být maximálně číslo {{max}}.',
            isNotFloatOneDecimal: '{{label}} musí být číslo, max. 1 desetinné místo.',
            arrayLength: '{{label}} musí mít alespoň {{length}} prvků.',
            uniqueError: 'Hodnota pro {{label}} již existuje.',
            emailsArrayPattern: 'Některá ze zadaných e-mailových adres není ve správném formátu.',
        },
    },
};
